/* FORM ELEMENTS *****************************************************************/
.formelement_basic {
  border: 1px solid #eb0000;
  padding: 8px 20px;
  margin: 0.6em 0;
  display: block;
  /*border-radius: 4px;*/
  box-sizing: border-box;
  font-size: 15px;
  font-weight: bold;
  width: 100%;
  min-width: 20em;
}
#additionalInfoToggle {
  display: block;
  cursor: pointer;
  text-align: center;
}
#additionalInfo div {
  cursor: auto;
  transition: max-height 0.5s ease;
  max-height: 0;
  overflow: hidden;
}

.send-form {
  text-align: left;
  color: #000000;
  font-size: 17px;
  box-sizing: border-box;
}

.msg-field {
  resize: vertical;
  padding-right: 20px;
  font-family: "Helvetica Neue LT", "Helvetica Neue", Helvetica, Arial,
    "Nimbus Sans L", sans-serif;
}

.file-input {
  display: none;
}

.container {
  width: 98%;
  max-width: 35em;
  margin: 4px 0;
}

.container p {
  color: red;
  text-align: center;
}

.drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 200px;
  border: 2px dashed #eb0000;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.upload-icon {
  width: 50px;
  height: 50px;
  background: url(../images/upload.svg) no-repeat center center;
  background-size: 100%;
  text-align: center;
  margin: 0 auto;
  padding-top: 30px;
}

.drop-message {
  text-align: center;
  color: #eb0000;
  font-size: 20px;
}

.file-progressBar {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  display: inline-block;
  margin: 0px 10px 5px 5px;
  vertical-align: top;
  flex: 6 !important;
  margin-left: 50px;
  box-sizing: content-box;
  width: calc(100% - 50px);
}

.file-progressBar div {
  height: auto;
  color: #fff;
  text-align: right;
  line-height: 15px;
  width: 0;
  background-color: #4caf50;
  border-radius: 3px;
  font-size: 13px;
}
.file-upload-btn {
  color: white;
  text-transform: uppercase;
  outline: none;
  background-color: #eb0000;
  font-weight: bold;
  padding: 8px 15px;
  margin: 1em 0;
}

.file-display-container {
  position: relative;
}

.file-status-bar {
  width: 100%;
  vertical-align: top;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  line-height: 50px;
  height: 50px;
}

.file-status-bar > div {
  /* overflow: hidden; */
}

.file-type {
  display: inline-block !important;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  margin-top: 25px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #0080c8;
  text-transform: uppercase;
}

.file-name {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #000;
}

.file-error {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #eb0000;
}

.file-error-message {
  color: red;
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  line-height: 1.5em;
}
.file-error-message .tooltiptext {
  font-size: x-small;
  visibility: hidden;
  width: 400px;
  background-color: #555;
  color: #fff;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -200px;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: pre-line;
  line-height: 1em;
}

.file-error-message .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.file-error-message:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.file-type-logo {
  width: 50px;
  height: 50px;
  background: url(../images/generic.png) no-repeat center center;
  background-size: 100%;
  position: absolute;
}

.file-size {
  display: inline-block;
  vertical-align: top;
  color: #30693d;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
}

.file-remove {
  position: absolute;
  top: 20px;
  right: 10px;
  line-height: 15px;
  cursor: pointer;
  color: red;
  margin-right: -10px;
}

.modal {
  z-index: 999;
  display: none;
  overflow: hidden;
}

.modal .overlay {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.66);
  position: absolute;
  top: 0;
  left: 0;
}

.modal .modal-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.modal-image-text {
  position: absolute;
  color: red;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  cursor: pointer;
}

.all-modal {
  z-index: 999;
  display: none;
  overflow: hidden;
}

.all-modal .overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.66);
  position: fixed;
  top: 0;
  left: 0;
}

.progress-container {
  background: white;
  width: 500px;
  /*height: 300px;*/
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  padding: 2em;
}

.progress-container span {
  display: block;
  text-align: center;
  justify-content: center;
  font-size: 20px;
}

.progress {
  width: 100%;
  position: relative;
  background-color: #efefef;
  height: 20px;
  border-radius: 5px;
  margin-top: 1em;
}

.progress-bar {
  position: absolute;
  background-color: #eb0000;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  color: white;
  font-weight: bold;
}

.otp-container {
  text-align: center;
  background: white;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  padding: 2em;
}

.otp-container span {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  font-size: 20px;
}

.otp {
  text-align: center;
  color: #eb0000;
  font-family: Arial;
  font-size: 17px;
  box-sizing: border-box;
  position: relative;
}

.otp-textinput {
  border: 2px solid #eb0000;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
}

.otp-btn {
  display: block;
  color: white;
  text-transform: uppercase;
  outline: none;
  background-color: #eb0000;
  font-weight: bold;
  padding: 8px 15px;
  margin: 0 auto;
}

.ok-btn {
  display: block;
  color: white;
  text-transform: uppercase;
  outline: none;
  background-color: #eb0000;
  font-weight: bold;
  padding: 8px 15px;
  margin: 1em auto 0 auto;
}

.error {
  color: red;
}

.disclaimer {
  padding: 1em;
  font-size: 0.8em;
  vertical-align: middle;
}
