.title {
  font-size: 2rem;
  text-align: center !important;
  font-weight: bold;
}

.logo {
  margin-top: 2%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  min-width: 15em;
}

.content-dropzone {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}